/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import schoolApi from 'api-client/school'
import { CATCHMENT_LEVELS, SCHOOL_SUBTYPE } from '@/constants'

// The `calendar_year` from the SQL DB response will often not match the actual
// year we want to filter on. In the DB there can be multiple catchments for a school
// that differ based on `calendar_year`. The `calendar_year` can either be the an actual year
// (e.g. 2020) or '0' to indicate any future year beyond any actual years specified.
// e.g. for a given school, if there are for calendar_year entries: 2020, 2021 and 0,
// then 0 would signify any years from 2022 onwards.
// There may also be schools without a 0 calendar_year entry, e.g. just 2020 and 2021. In this case
// any future year would be based on the latest specified year (i.e. 2021).
// The below function turns an actual year into its equivalent DB calendar_year for a given school,
// based on all the calendar_years defined for the school.
const actualYearToDBYear = (allDBYearsForSchool, actualYear) => {
  const sortedYears = [...allDBYearsForSchool].sort()
  const maxYear = sortedYears[sortedYears.length - 1]
  const minYear = sortedYears[0] || sortedYears[1] // Minimum non-zero year
  if (sortedYears.includes(actualYear)) {
    return actualYear
  }
  if (actualYear > maxYear) {
    return sortedYears.includes(0) ? 0 : maxYear
  }
  // Implicitly actual year < minYear
  return minYear
}

const schoolModule = {
  state: {
    pilotSchools: null,
    school: null, // url school code data from pilot json
    localSecondarySchools: null,
    selectionCriteria: {},
    schoolCustomisations: {} // Web customisation as defined by the school in SI
  },
  getters: {
    pilotSchools: (state) => state.pilotSchools,
    y67tPilotSchools: (state) => state.pilotSchools?.filter(({ y67t }) => y67t),
    school: (state) => state.school,
    localSecondarySchools: (state) => state.localSecondarySchools,
    defaultSelectionCriteria: (state, getters, rootState) =>
      rootState?.refData?.customisationDefinitions?.ooa?.criteria
        ?.filter((criterion) => !criterion.allowReorder) // only include locked criteria by default
        ?.sort((c1, c2) => c1.defaultOrder - c2.defaultOrder),
    selectionCriteria: (state, getters, rootState) => (schoolCode) => {
      const criteriaDefinitions =
        rootState?.refData?.customisationDefinitions?.ooa?.criteria
      const schoolCriteria =
        state.schoolCustomisations?.[schoolCode]?.ooa?.criteria
      return schoolCriteria
        ? criteriaDefinitions
            ?.map((definition) => {
              const schoolCriterion = schoolCriteria.find(
                ({ code }) => code === definition.code
              )
              return {
                ...definition,
                active: definition.active && schoolCriterion?.enabled,
                sortOrder: schoolCriterion?.order || 9999
              }
            })
            ?.sort(
              (criterion1, criterion2) =>
                criterion1.sortOrder - criterion2.sortOrder
            )
        : getters.defaultSelectionCriteria
    },
    captureSiblingInfo: (state, rootState) => (schoolCode) => {
      const schoolCustomisations = state.schoolCustomisations?.[schoolCode]
      return schoolCustomisations
        ? schoolCustomisations?.ooa?.captureSiblingInfo
        : rootState?.customisationDefinitions?.ooa?.captureSiblingInfo
    },
    validY67TPrimarySchool: (state, getters) => (schoolCode) => {
      const y67tSchool = getters.y67tPilotSchools.find(
        (school) => school.schoolCode === schoolCode
      )
      if (
        [CATCHMENT_LEVELS.PRIMARY, CATCHMENT_LEVELS.CENTRAL].includes(
          y67tSchool?.catchmentLevel
        )
      ) {
        return y67tSchool
      }
      return null
    },
    schoolCode: (state) => state?.school?.schoolCode,
    schoolCustomisations: (state) => state.schoolCustomisations
  },
  mutations: {
    setPilotSchools(state, pilotSchools) {
      state.pilotSchools = pilotSchools
    },
    setSchool(state, school) {
      state.school = school
    },
    setLocalSecondarySchools(state, schools) {
      state.localSecondarySchools = schools
    },
    setSchoolCustomisation(state, { schoolCode, customisation }) {
      state.schoolCustomisations[schoolCode] = customisation
    },
    setPredictiveSchools(state, predictiveSchools) {
      state.predictiveSchools = predictiveSchools
    }
  },
  actions: {
    async getPilotSchools({ commit }) {
      commit('setPilotSchools', await schoolApi.getPilotSchools())
    },
    async getSchoolCustomisation({ commit }, schoolCode) {
      const customisation = await schoolApi.getSchoolCustomisation(schoolCode)
      commit('setSchoolCustomisation', { schoolCode, customisation })
      return customisation
    },
    setLocalSecondarySchools({ commit }, { schools }) {
      commit('setLocalSecondarySchools', schools)
    },
    async getSchoolsForAddress(_, { url, longitude, latitude }) {
      const allSchoolCatchments = (
        await schoolApi.getSchoolsForAddress(url, longitude, latitude)
      )?.data?.rows
      const nextYear = allSchoolCatchments?.[0]?.next_year

      return allSchoolCatchments.filter((schoolCatchment) => {
        const nextYearAsDBYear = actualYearToDBYear(
          schoolCatchment.all_calendar_years,
          nextYear
        )
        return (
          schoolCatchment.calendar_year === nextYearAsDBYear &&
          SCHOOL_SUBTYPE.includes(schoolCatchment.school_subtype)
        )
      })
    }
  }
}

export default schoolModule
